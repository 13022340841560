window.addEventListener("load", function () {
  $(".slick-slider").slick({
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  });
  $(".slick_wrapper").css("display", "unset");
  $(".loader").css("display", "none");
  $(".slick-slider").slick("slickGoTo", 0, true);

  const element = document.getElementById("slider");

  element.addEventListener("contextmenu", function (event) {
    event.preventDefault();
  });
});
